<template>
  <div id="slide-image-and-description">
    <!------------ HAS IMAGE AND DESCRIPTION ------------------------------------------------------>
    <div v-if="displayImage && slideDescription">
      <div
        v-if="layoutFormat === 'image-left' || layoutFormat === 'image-left-large'"
        :class="'horz ' + layoutFormat"
      >
        <div class="image">
          <img
            :src="imageUrl"
            :alt="slide.image_alt_text"
          >
        </div>
        <div class="description">
          <div v-html="slideDescription" />
        </div>
      </div>

      <div
        v-else-if="layoutFormat === 'image-right'"
        :class="'horz ' + layoutFormat"
      >
        <div class="description">
          <div v-html="slideDescription" />
        </div>
        <div class="image">
          <img
            :src="imageUrl"
            :alt="slide.image_alt_text"
          >
        </div>
      </div>

      <div
        v-else-if="layoutFormat === 'image-top'"
        :class="'vert ' + layoutFormat"
      >
        <div class="image">
          <img
            :src="imageUrl"
            :alt="slide.image_alt_text"
          >
        </div>
        <div class="description">
          <div v-html="slideDescription" />
        </div>
      </div>

      <div
        v-else-if="layoutFormat === 'image-bottom'"
        :class="'vert ' + layoutFormat"
      >
        <div class="description">
          <div v-html="slideDescription" />
        </div>
        <div class="image">
          <img
            :src="imageUrl"
            :alt="slide.image_alt_text"
          >
        </div>
      </div>
    </div>

    <!------------ ONLY HAS DESCRIPTION ----------------------------------------------------------->
    <div v-else-if="slideDescription">
      <div class="description">
        <!-- eslint-disable vue/no-v-html -->
        <div v-html="slideDescription" />
        <!--eslint-enable-->
      </div>
    </div>

    <!------------ ONLY HAS IMAGE ----------------------------------------------------------------->
    <div v-else-if="displayImage">
      <div class="image-vert">
        <img :src="imageUrl" :alt="slide.image_alt_text">
      </div>
    </div>
  </div>
</template>

<script>
import * as DOMPurify from 'dompurify'

export default {
  name: 'SlideImageAndDescription',
  props: {
    slide: Object,
    overrideDescription: {
      type: String,
      default: '',
    },
    allowImages: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    imageUrl() {
      return this.slide?.image_urls[0]
    },
    layoutFormat() {
      return this.slide.layout_format
    },
    slideDescription () {
      return this.overrideDescription || DOMPurify.sanitize(this.slide.description)
    },
    displayImage() {
      return this.allowImages && this.slide.image_urls.length
    },
  },
}
</script>

<style lang="scss" scoped>

#slide-image-and-description {
  .image-left-large {
    .image {
      margin-right: 4.0em !important;
      flex: 0 1 40% !important;
    }
  }
  .horz {
    display: flex;
    flex-direction: row;
    flex-shrink: 1;
    justify-content: center;
    > :first-child {
      margin-right: 2.0em;
    }
    .image {
      flex: 0 1 20%;
      img {
        width: 100%;
        justify-self: center;
      }
    }
    .description {
      width: 100%;
      display: flex;
      flex: 0 1 100%;
      flex-direction: column;
      justify-content: center;
      margin: 0;
      div {
        margin: 0;
      }
    }
  }
  .vert {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    :first-child {
      margin-bottom: 1.875em;
    }
    .image {
      margin: 0 auto;
      display: flex;
      justify-content: center;

      img {
        max-height: 11em;
      }
    }
  }
  .image-vert {
    text-align: center;
    height: 25em;
    img {
      height: 100%;
    }
  }
}


@media only screen and (max-width: 660px) {
  #slide-image-and-description {
    width: 100%;

    .horz {
      .description {
        width: 100%;
        flex: 0 0 100%;
      }

      .image {
        display: none;
      }
    }

    .image-vert {
      height: 100%;

      img {
        width: 100%;
      }
    }
  }
}
</style>
